.App {
    text-align: center;
    color: #000;
    font-family: Arial Unicode MS;
    font-size: 16px;
}

.App-logo {
  height: 64px;
}

.App-header {
  background-color: white;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url("pattern.png");
  background-repeat: space repeat;
}

h1.header-line {
    color: #000;
    font-family: 'Archivo Black', sans-serif;
    font-weight: 400;
    font-size: 36px;
    margin-bottom: 14px;
}

h1.header-line>span.blue-header-part {
    color: #2185d0;
}

span.sub-header {
    color: #000;
    font-family: Arial Unicode MS;
    font-size: 16px;
    margin-bottom: 60px;
}

img.csv {
    width: 64px;
    margin-right: 26px;
}

div.download-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.result-area {
    margin-top: 26px;
    margin-bottom: 26px;
    width: 526px;
}

div.result-area>p {
    margin-bottom: 26px;
}

div.dropzone {
    width: 526px;
    height: 353px;

    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 20px 6px rgba(0, 95, 237, 0.08);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
